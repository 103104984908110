import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'store/useStore'
import { useCurrentRouteIndex } from 'utils/hooks'

const useNavigationRoutes = () => {
  const [queryParams, setQueryParams] = useState<string | null>(null)

  const location = useLocation()
  const navigate = useNavigate()
  const currentIndexRoute = useCurrentRouteIndex()

  const routes: string[] = useStore((state) => state.routes)

  const canNavigateNext = currentIndexRoute + 1 < routes.length
  const canNavigateBack = currentIndexRoute - 1 >= 0

  const nextRoute = routes[currentIndexRoute + 1]
  // const previousRoute = routes[currentIndexRoute - 1]

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const newParams = []
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of params.entries()) {
      newParams.push([key, value])
    }
    const paramsString = newParams?.map(([key, value]) => `${key}=${value}`).join('&')
    setQueryParams(paramsString ?? null)
    // eslint-disable-next-line
  }, [])

  const navigateNext = useCallback(
    (to?: string) => {
      if (to && routes?.includes(to)) {
        navigate(`../${to}?${queryParams}`)
      } else if (canNavigateNext && nextRoute) {
        navigate(`../${nextRoute}?${queryParams}`)
      }
    },
    [navigate, routes, canNavigateNext, nextRoute, queryParams], // обновленный список зависимостей
  )

  const navigateBack = useCallback(() => {
    if (canNavigateBack) {
      navigate(-1)
    }
  }, [canNavigateBack, navigate])

  return {
    navigateNext,
    navigateBack,
    canNavigateNext,
    canNavigateBack,
  }
}

export default useNavigationRoutes
