import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { isDev } from 'config/flags'
import Onboardings from 'onboardings'
import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from 'store/useStore'
import { analyticEvent, funnelVersions, getFunnelFromLocalStorage } from 'utils'

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_API_CLIENT_KEY,
  enableDevMode: isDev,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // eslint-disable-next-line no-console
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    })

    analyticEvent({
      name: 'experiment_viewed',
      gtagData: {
        experiment_id: experiment.key,
        variation_id: result.key,
        gb_user_id: `${result.hashValue}~`,
      },
    })
  },
})

const App = () => {
  const funnel = useStore((state) => state.funnel)

  useEffect(() => {
    localStorage.setItem('version', funnelVersions[getFunnelFromLocalStorage()])
    growthbook?.loadFeatures()
  }, [])

  useEffect(() => {
    localStorage.setItem('funnel', funnel)
  }, [funnel])

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <BrowserRouter>
        <Onboardings />
      </BrowserRouter>
    </GrowthBookProvider>
  )
}

export default App
