import { Navigate, Route, Routes } from 'react-router-dom'

import { useGrowthbookFeatureVariation } from '../utils/hooks'
import { ExperimentVariant, Experiments } from '../utils/hooks/useGrowthbookFeatureVariation'
import PalmistryOnboarding from './palmistry-onboarding'
import { paths as palmistryPaths } from './palmistry-onboarding/paths'

const Onboardings = () => {
  const PS = useGrowthbookFeatureVariation({ key: Experiments.PersonalStatement })
  const PSIsOn = PS === ExperimentVariant.first
  const skipPages = PSIsOn ? undefined : [palmistryPaths.personalStatement]

  return (
    <Routes>
      <Route path="/palmistry/*" element={<PalmistryOnboarding skipPages={skipPages} />} />
      <Route path="/palmistry2/*" element={<PalmistryOnboarding skipPages={[palmistryPaths.birthday]} />} />
      <Route path="/*" element={<Navigate to="/palmistry/*" replace />} />
    </Routes>
  )
}

export default Onboardings
