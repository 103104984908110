import { isDev } from './flags'

export const baseURL = isDev ? 'https://dev01.dev.corp.hint.app' : 'https://payment.hint.app'

export const userCountryLink = 'https://ip.hintw.workers.dev/'
export const analyzePhotoLink = isDev
  ? 'https://dev01.dev.corp.hint.app/palmistry/api/processing1'
  : 'https://palmistry.hint.app/api/processing'

export const basePurchaseUrl = isDev
  ? 'https://dev.purchase.hint.app/payment-3'
  : 'https://purchase-2.hint.app/payment-3'

export const baseOldPurchaseUrl = isDev
  ? 'https://dev.purchase.hint.app/payment-3'
  : 'https://purchase.hint.app/payment'

export const eulaLink = 'https://hint.app/eula'

export const privacyLink = 'https://hint.app/privacy-notice'

export const helpCenterLink = 'https://help.hint.app/'

export const supportLink = 'https://help.hint.app/hc/hint-help-center/articles/1696510342-get-help-from-hint-support'
