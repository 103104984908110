import Logo from 'assets/logos/Logo'
import { FC, memo } from 'react'

import ButtonBack from './button-back'
import styles from './styles.module.scss'

const Header: FC = () => {
  return (
    <header className={styles.header}>
      <ButtonBack />
      <Logo />
    </header>
  )
}

export default memo(Header)
